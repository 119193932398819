<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Phiếu nhập hàng</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-radio-group v-model="selectedStatus" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Nháp" :value="0"></v-radio>
          <v-radio label="Đã nhập" :value="1"></v-radio>
          <v-radio label="Bị hủy" :value="-1"></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhà cung cấp</div>
        <v-autocomplete
          v-model="selectedSuppliers"
          class="tp-filter-autocomplete"
          :items="suppliers"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="code"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn nhà cung cấp"
        ></v-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    statusFilter: {
      type: [Number, String]
    },
    suppliersFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    typeFilter: {
      type: [Number, String]
    }
  },
  computed: {
    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    },
    selectedType: {
      get() {
        return this.typeFilter;
      },
      set(val) {
        this.$emit("updateTypeFilter", val);
      }
    },
    selectedSuppliers: {
      get() {
        return this.suppliersFilter;
      },
      set(val) {
        this.$emit("updateSuppliersFilter", val);
      }
    },
    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    }
  },
  created() {
    this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
  },
  methods: {
    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
