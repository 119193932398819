<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="goodsReceiptStatusRequest.value === 'loading-getGoodsReceipts'"
    loading-text="Đang tải dữ liệu"
    :items="goodsReceipts"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <tr class="yellow lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">
            {{ goodsReceiptListTotals.total_imported | formatCurrency }}
          </td>
          <td class="font-weight-bold">
            {{ goodsReceiptListTotals.total_bill | formatCurrency }}
          </td>
          <td class="font-weight-bold">
            {{ goodsReceiptListTotals.total_payment | formatCurrency }}
          </td>
          <td></td>
        </tr>
        <tr v-for="item in items" :key="item.id" @click="viewDetail(item)">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.code)"
                >
                  {{ item.code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.po_code)"
                >
                  {{ item.po_code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.branch_name }}</td>
          <td>{{ item.created_user_name }}</td>
          <td>{{ item.supplier_name }} - {{ item.supplier_code }}</td>
          <td>{{ item.code_ncc ? item.code_ncc : "N/A" }}</td>
          <td>
            <span v-if="item.goods_value !== 'N/A'">{{
              item.goods_value | formatCurrency
            }}</span>
            <span v-else>{{ item.goods_value }}</span>
          </td>
          <td>{{ item.value | formatCurrency }}</td>
          <td>{{ item.paid ? item.paid : 0 | formatCurrency }}</td>
          <td class="text-center">
            <v-chip
              class="font-weight-bold"
              :color="
                item.status === 1
                  ? 'green'
                  : item.status === -1
                  ? 'red accent-2'
                  : 'grey'
              "
              small
              outlined
            >
              {{
                item.status === 1
                  ? "Đã nhập"
                  : item.status === -1
                  ? "Bị hủy"
                  : "Nháp"
              }}
            </v-chip>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td colspan="11">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã phiếu",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Mã P/O",
          align: "start",
          sortable: false,
          value: "po_code"
        },
        {
          text: "Thời gian nhập",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Kho",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Người nhập",
          align: "start",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Nhà cung cấp",
          align: "start",
          sortable: false,
          value: "supplier_code"
        },
        {
          text: "Mã HĐ NCC",
          align: "start",
          sortable: false,
          value: "code_ncc"
        },
        {
          text: "Giá trị hàng nhập",
          align: "start",
          sortable: false,
          value: "goods_value"
        },
        {
          text: "Giá trị hóa đơn",
          align: "start",
          sortable: false,
          value: "value"
        },
        {
          text: "Thanh toán",
          align: "start",
          sortable: false,
          value: "paid"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    goodsReceipts() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipts"];
    },
    goodsReceiptStatusRequest() {
      return this.$store.getters["GOODS_RECEIPT/statusRequest"];
    },
    goodsReceiptListTotals() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceiptListTotals"];
    }
  },
  methods: {
    getIndexOfGoodsReceipt(val) {
      const arr = this.goodsReceipts.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$store.dispatch("GOODS_RECEIPT/getGoodsReceiptById", item.id);

      await this.$router.push({
        name: "transaction_goods-receipts_notes-detail",
        params: {
          goodsReceiptId: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
