var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.goodsReceiptStatusRequest.value === 'loading-getGoodsReceipts',"loading-text":"Đang tải dữ liệu","items":_vm.goodsReceipts,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',[_c('tr',{staticClass:"yellow lighten-5"},[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.goodsReceiptListTotals.total_imported))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.goodsReceiptListTotals.total_bill))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.goodsReceiptListTotals.total_payment))+" ")]),_c('td')]),_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.po_code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.po_code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1),_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_vm._v(_vm._s(item.branch_name))]),_c('td',[_vm._v(_vm._s(item.created_user_name))]),_c('td',[_vm._v(_vm._s(item.supplier_name)+" - "+_vm._s(item.supplier_code))]),_c('td',[_vm._v(_vm._s(item.code_ncc ? item.code_ncc : "N/A"))]),_c('td',[(item.goods_value !== 'N/A')?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.goods_value)))]):_c('span',[_vm._v(_vm._s(item.goods_value))])]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.value)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.paid ? item.paid : 0)))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
                ? 'green'
                : item.status === -1
                ? 'red accent-2'
                : 'grey',"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã nhập" : item.status === -1 ? "Bị hủy" : "Nháp")+" ")])],1)])})],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"11"}},[_vm._v("Không có dữ liệu")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }